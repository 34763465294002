import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

// Make sure to replace with your Stripe publishable key
const { REACT_APP_STRIPE_API_URL, REACT_APP_API_URL } = process.env;

const CheckoutForm = ({ cartItems, stripePromise }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleCheckout = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      const { data: { sessionId: sessionId } } = await axios.post(
        `${REACT_APP_API_URL}api/ecommerce/v1/create-checkout-session`,
        { cartItems }
      );

      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error("Stripe Checkout error:", error);
      }
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  return (
    <form onSubmit={handleCheckout} className='w-100'>
      <CardElement />
      <button type="submit" disabled={!stripe || !elements} className='bg-black text-white btn w-100 mt-2'>
        Pay with Stripe
      </button>
    </form>
  );
};

const StripeCheckoutButton = ({ cartItems }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeStripe = async () => {
      try {
        const { data: { stripeAccount } } = await axios.post(
          `${REACT_APP_API_URL}api/ecommerce/v1/create-checkout-session`,
          { cartItems }
        );

        const stripe = loadStripe(REACT_APP_STRIPE_API_URL, { stripeAccount });
        setStripePromise(stripe);
      } catch (error) {
        console.error("Error initializing Stripe:", error);
      } finally {
        setLoading(false);
      }
    };

    initializeStripe();
  }, [cartItems]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm cartItems={cartItems} stripePromise={stripePromise} />
    </Elements>
  );
};

export default StripeCheckoutButton;
