import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate
import Api from "../api/api";
import apiUtil from '../api/apiUtil';
import Loading from '../assets/loading.gif'

const MoreMenu = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const [isLoading, setIsLoading] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    callReadApi();
  }, []);

  const callReadApi = useCallback(() => {
    
    let param = {
      type: "top",
    };
    

    setIsLoading(true);
    const getData = Api.read('tab', param );

    Promise.all([getData]).then((results) => {
      apiUtil.parseResult(results[0], (data) => {
        const dataArr = data.data ? data.data : [];
        setTabs(dataArr);
        setIsLoading(false);
      }, (error, type) => {
        setTabs([]);
        setIsLoading(false);
      });
    });
  }, []);

  const handleNavigation = (name, id) => {
    navigate(`/pages/${name.toLowerCase()}`, { state: { id } });
  };

  if (isLoading) {
    return <div className="text-center my-5 d-flex justify-content-center align-items-center" style={{minHeight: '400px'}}>
      <img src={Loading} className="img-fluid" style={{objectFit: 'cover', width : '300px', height: '300px'}} />
    </div>;
  }

  return (
    <div className='container-fluid m-4'>
      <div className="row m-4">
        {tabs.map((section) => (
          section ? (<div key={section.id} className='col-12 col-lg-4 gap-2'>
            <span className="footer-title w-100">{section.name}</span>
            {section.tabs && section.tabs.length > 0 ? (
              section.tabs.map((tab) => (
                <div
                  key={tab.id}
                  onClick={() => handleNavigation(tab.name, tab.id)}
                  className="link link-hover  cursor-pointer"
                >
                  {tab.name}
                </div>
              ))
            ) : (
              <p className=''>No {section.name} available</p>
            )}
          </div>) :
          (
            <p className=''>No Menu available yet</p>
          )
        ))}
      </div>
    </div>
  );
};

export default MoreMenu;
