import './App.css';
import { useState } from 'react';
import NavBar from './components/NavBar';
import Body from './components/Body';
import SimpleSlider from './components/HeroCarousel';
import HeroSection from './components/HeroSection';
import OurBestSellers from './components/OurBestSellers';
import Ingridients from './components/Ingridients';
import JournalSection from './components/JournalSection';
import BsText from './components/BsText';
import { Route, Routes } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import SinglePage from "./components/SinglePage";
import JournalPage from "./components/JournalPage";
import Cart from './components/Cart';
import FollowONIG from './components/FollowONIG';
import Products from './components/Products';
import CartHold from './components/CartHold';
import SPFooter from './components/SPFooter';
import Under20 from './components/Under20';
import Under10 from './components/Under10';
import ForHer from './components/ForHer';
import ForHim from './components/ForHim';
import MobileNav from './components/MobileNav';
import Branding from './components/FooterComponents/Branding';
import Design from './components/FooterComponents/Design';
import Marketing from './components/FooterComponents/Marketing';
import Advertisement from './components/FooterComponents/Advertisement';
import AboutUs from './components/FooterComponents/AboutUs';
import ContactUs from './components/FooterComponents/ContactUs';
import Jobs from './components/FooterComponents/Jobs';
import PressKit from './components/FooterComponents/PressKit';
import TermsOfUse from './components/FooterComponents/TermsOfUse';
import ContentPage from './components/FooterComponents/ContentPage';
import PrivacyPolicy from './components/FooterComponents/PrivacyPolicy';
import PaymentSuccess from './components/stripe/PaymentSuccess';
import ViewOrder from './components/stripe/ViewOrder';
import SearchOrder from './components/stripe/SearchOrder';
import PaymentCancel from './components/stripe/PaymentCancel';
import Review from './components/Review';
import { ToastContainer, toast } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App">
      <BrowserRouter>

        <Routes >
          <Route path='/' exact element={<>   <NavBar /> <MobileNav />  <SimpleSlider />  <HeroSection />  <BsText />  {<Products />}  {/*<OurBestSellers />*/} <FollowONIG /> <SPFooter />  </>} />
          <Route path='/:id' exact element={<> <NavBar /> <MobileNav />  <SinglePage /> </>} />
          <Route path='/pages/:name' exact element={<> <NavBar /> <MobileNav />  <ContentPage />  <SPFooter />  </>} />
          {/* <Route path='/journal/april' element={<> <NavBar /> <MobileNav />  <JournalPage /> </>} /> */}
          <Route path='/cart' exact element={<>  <NavBar /> <CartHold /></>} />
          <Route path='/under20' element={<> <NavBar />  <Under20 /> </>} />
          <Route path='/under40' element={<> <NavBar />  <Under10 /> </>} />
          <Route path='/forher' element={<> <NavBar />  <ForHer /> </>} />
          <Route path='/forhim' element={<> <NavBar />  <ForHim /> </>} />
          <Route path="/branding" element={<> <NavBar /> <MobileNav />  <Branding /> <SPFooter /></>} />
          <Route path="/design" element={<> <NavBar /> <MobileNav />  <Design /> <SPFooter /></>} />
          <Route path="/marketing" element={<> <NavBar /><MobileNav />   <Marketing /> <SPFooter /></>} />
          <Route path="/advertisement" element={<> <NavBar /> <MobileNav />  <Advertisement /> <SPFooter /></>} />
          <Route path="/about-us" element={<> <NavBar /> <MobileNav />  <AboutUs /> <SPFooter /></>} />
          <Route path="/pages/contact-us" element={<> <NavBar /> <MobileNav />  <ContactUs /> <SPFooter /></>} />
          <Route path="/jobs" element={<> <NavBar /> <MobileNav />  <Jobs /> <SPFooter /></>} />
          <Route path="/press-kit" element={<> <NavBar />  <MobileNav /> <PressKit /> <SPFooter /></>} />
          <Route path="/terms-of-use" element={<> <NavBar />  <MobileNav /> <TermsOfUse /> <SPFooter /></>} />
          <Route path="/privacy-policy" element={<> <NavBar />  <MobileNav /> <PrivacyPolicy /> <SPFooter /></>} />

          <Route path="/payment-success" element={<> <NavBar />  <MobileNav /> <PaymentSuccess /> <SPFooter /></>} />
          <Route path="/view-order/:order_number" element={<> <NavBar />  <MobileNav /> <ViewOrder /> <SPFooter /></>} />
          <Route path="/search-order" element={<> <NavBar />  <MobileNav /> <SearchOrder /> <SPFooter /></>} />
          <Route path="/payment-cancel" element={<> <NavBar />  <MobileNav /> <PaymentCancel /> <SPFooter /></>} />
          <Route path="/review" element={<> <NavBar />  <MobileNav /> <Review /> <SPFooter /></>} />
          {/* <Route path="/cookie-policy" element={<> <NavBar />  <CookiePolicy /> </>} /> */}
        </Routes>

      </BrowserRouter>
      <ToastContainer className="custom-toast" autoClose={false} />


    </div>
  );
}

export default App;
