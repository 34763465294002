import React from 'react'
import "../styles/HeroSection.css"
import one from "../assets/innovation.png"
import two from "../assets/Quality.png"
import three from "../assets/userCentric.png"


const HeroSection = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='pt-4 col-12'>
                <p className='text-center fw-bold fs-2'>WELCOME TO OUR STORE</p>
                </div>
                <div className='pt-4 col-12 w-100 text-center'>
                    <p className='text-xl text-center'> The tech brand that's transforming the future. Innovative products designed to bridge the physical and digital worlds seamlessly. </p>
                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                    <img src={one} className=" w-20" />
                    <p> Powered By Innovation </p>
                    <p className='text-center'> Phygital technology that enhances your < br />
                        everyday experiences with seamless connectivity.</p>
                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                    <img src={two} className=" w-20" />
                    <p> Exceptional Quality </p>
                    <p className='text-center'>Engineered to perfection, ensuring durability <br />
                        and superior performance in every product. </p>

                </div>
                <div className='col-12 col-lg-4 pt-4 d-flex flex-column justify-content-center align-items-center'>
                    <img src={three} className=" w-20" />
                    <p> Skin First Formulas </p>
                    <p className='text-center'> Crafted with your needs in mind, delivering <br /> intuitive and interactive solutions for a smarter life.</p>
                </div>
            </div>
        </div>
    )
}

export default HeroSection