import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import DOMPurify from 'dompurify';
import "../styles/SinglePageFAQ.css";

const SinglePageFAQ = (props) => {
  const { description, how } = props;

  const createMarkup = (htmlContent) => {
    const sanitizedContent = DOMPurify.sanitize(htmlContent);
    return { __html: sanitizedContent };
  };

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Accordion.Item eventKey="0">
          <Accordion.Header>PRODUCT INFO</Accordion.Header>
          <Accordion.Body>
            <div
              className="product-description"
              dangerouslySetInnerHTML={createMarkup(description)}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Card>
      {how && (
        <Card>
          <Accordion.Item eventKey="1">
            <Accordion.Header>HOW TO USE</Accordion.Header>
            <Accordion.Body>
              <div
                className="product-description"
                dangerouslySetInnerHTML={createMarkup(how)}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Card>
      )}
    </Accordion>
  );
};

export default SinglePageFAQ;
